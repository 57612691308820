import React, { Fragment, useEffect, useContext } from "react"
import Img from "gatsby-image"

import Layout from "layout"
import Container from "layout/Container"

import { isBrowser } from "services/general"

import styles from "./utils/bookingComplete.module.scss"
import { useBookingCompleteImages } from "./hooks/useBookingCompleteImages"
import { MedEnsureContext } from "../MedEnsureContext/MedEnsureContext"

const Completed = (props) => {
  const { pageContext } = props
  const { module, content } = pageContext
  const data = useBookingCompleteImages()
  const { medEnsureDispatch } = useContext(MedEnsureContext)

  useEffect(() => {
    if (isBrowser()) {
      medEnsureDispatch({ type: "GET_CONTEXT_FROM_SESSION" })
    }

    const restartDetails = async () => {
      await medEnsureDispatch({ type: "REMOVE_CONTEXT_FROM_SESSION" })
    }
    restartDetails()
  }, [medEnsureDispatch])

  return (
    <Layout
      seoTitle={module.seoTitle}
      pageContext={pageContext}
      display={{ footer: true, helpCenterBanner: false }}
    >
      <Container isCentered>
        <center>
          <Container isCentered mobile={10} tablet={6} desktop={8}>
            {content.icon && (
              <Img
                fluid={data[content.icon].childImageSharp.fluid}
                alt={`${module.name} Complete`}
                className={styles["image"]}
              />
            )}
          </Container>
          <h1 className="title has-text-primary">
            {module.title}
          </h1>

          <div className="column">
            <Fragment>
              <h4 className="mb-2">
                Thank you for submitting your response to our teleconsult
                survey.
                <br />
                <br />
                Please check {"  "}
                <a
                  href={`mailto:${props?.location?.state?.email}`}
                  target="_blank"
                  rel="noreferrer noopener"
                  className="has-text-weight-bold"
                >
                  {props?.location?.state?.email}
                </a>{" "}
                for your reference number. For faster processing, please provide
                your reference number if you have any inquiries.
              </h4>
            </Fragment>
          </div>
        </center>
      </Container>
    </Layout>
  )
}

export default Completed
